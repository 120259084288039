import React from 'react'
import { PageProps } from 'gatsby'

import * as styles from '../../../styles/pages/solutionsPage.module.scss'

import { SEO } from '../../../components/SEO'
import { SuccessCases } from '../../../components/SuccessCases'
import { AboutUs } from '../../../components/AboutUs'
import { Divider } from '../../../components/Divider'
import { Statistics } from '../../../components/Statistics'
import { Slider } from '../../../components/Slider'
import { SectionTitle } from '../../../components/SectionTitle'
import { Layout } from '../../../components/layout'
import {
  MethodologySection,
  FeaturesSection,
} from '../../../components/SolutionsPages'

import banner1 from '../../../images/pages/controladoria/viabilidadeFinanceira1.jpeg'
import banner2 from '../../../images/pages/controladoria/viabilidadeFinanceira2.jpeg'
import {
  faDollarSign,
  faQuestion,
  faChartPie,
  faFileInvoiceDollar,
} from '@fortawesome/free-solid-svg-icons'

const slides = [
  {
    title: <> ANÁLISE DE VIABILIDADE ECONÔMICO FINANCEIRA DE PROJETOS</>,
    description: (
      <>
        Obtenha uma análise de viabilidade econômico financeira de projetos e
        negócios
      </>
    ),
    backgroundUrl: banner1,
    sameOrigin: true,
    link: `#contato`,
    linkText: `Fale Conosco`,
  },
  {
    title: <>TOME DECISÕES ASSERTIVAS ANALISANDO A VIABILIDADE DO NEGÓCIO</>,
    description: (
      <>
        Não tenha mais dúvidas de quando captar recursos, realizar
        investimentos, expandir seu negócio ou mesmo iniciar um novo projeto
      </>
    ),
    backgroundUrl: banner2,
    sameOrigin: true,
    link: `#contato`,
    linkText: `Fale Conosco`,
  },
]

const cases = [
  {
    title: `Diman Serviços Mecânicos`,
    text: `Implantação da rotina de gestão através da reunião mensal de análise
    de resultados. Realizadas mudanças na gestão através da classificação
    adequada de gastos, sistematização de relatórios e planejamento
    orçamentário.`,
  },
  {
    title: `TPC Law`,
    text: `Implantação da rotina de gestão para controles operacionais e
    tomada de decisões pautada em KPIs, possibilitando um crescimento
    seguro e sustentável.`,
  },
  {
    title: `Tecca Engenharia de Sistemas`,
    text: `Após o projeto foram fechados dois grandes negócios, resultado de uma
    maior assertividade na formação do preço de venda. Trabalho feito
    com foco nos custos diretos e créditos e débitos de impostos.`,
  },
]

const EstudosViabilidade: React.FC<PageProps> = ({ location }) => {
  const featuresItems = [
    {
      faIcon: faDollarSign,
      title: `Viabilidade econômica financeira`,
      description: (
        <>
          A viabilidade econômica avalia custos e benefícios de um projeto. Já a
          viabilidade financeira estima o valor total de investimento necessário
          para executar um projeto na prática, levando em consideração capital
          de giro, receitas, despesas, rendimentos, entre outros.
        </>
      ),
    },
    {
      faIcon: faQuestion,
      title: `Por que fazer esta análise?`,
      description: (
        <>
          A análise de viabilidade econômica financeira busca medir se um
          investimento é seguro ou não, levando em consideração os custos e
          benefícios do projeto em questão, como por exemplo: abertura de um
          novo negócio, lançamento de produtos, expansão de negócios, dentre
          outros.
        </>
      ),
    },
    {
      faIcon: faChartPie,
      title: `Como é feita esta análise?`,
      description: (
        <>
          Para realizar a análise de viabilidade econômico financeira devem ser
          observados alguns pontos, como por exemplo, projeção de receitas,
          custos, despesas e investimentos, projeção de fluxo de caixa e
          indicadores: valor presente líquido, taxa interna de retorno e
          payback. Fale com um de nossos especialistas para começar agora mesmo.
        </>
      ),
    },
    {
      faIcon: faFileInvoiceDollar,
      title: `Project Finance`,
      description: (
        <>
          É uma modalidade de viabilidade econômica financeira para a realização
          de projetos de grande vulto, em que a principal fonte de receita para
          o pagamento da dívida do financiamento do produto ou serviço
          resultante é obtida do fluxo de caixa gerado pela própria operação.
        </>
      ),
    },
  ]

  const methodologyItems = [
    {
      count: 1,
      title: 'Introdução e objetivos',
      description: (
        <>
          Na primeira etapa do estudo de viabilidade econômico financeira são
          coletadas as informações necessárias para elaboração da introdução e
          objetivos do projeto, através de entrevistas, informações de sites,
          redes sociais e checklists. São levantados dados históricos da empresa
          para contextualização do projeto.
        </>
      ),
    },
    {
      count: 2,
      title: 'Validação de premissas',
      description: (
        <>
          Na segunda etapa são validadas as premissas com a empresa cliente e
          esclarecidas dúvidas. São levantadas todas as garantias e riscos para
          operacionalização do investimento e viabilidade do projeto
        </>
      ),
    },
    {
      count: 3,
      title: 'Projeções',
      description: (
        <>
          Nesta etapa são elaboradas as projeções de receitas, custos, despesas,
          investimentos, fluxo de caixa e indicadores como valor presente
          líquido, taxa interna de retorno, payback, hedge financeiro, dentre
          outros. São analisadas alternativas que viabilizem o projeto em
          questão.
        </>
      ),
    },
    {
      count: 4,
      title: 'Validação e entrega de cenários',
      description: (
        <>
          Nesta etapa são construídas e validadas análises de sensibilidade e
          cenários alternativos. As análises de sensibilidade são avaliações que
          procuram estimar o resultado gerado por mudanças plausíveis de se
          ocorrerem no mundo real, em custos, despesas e receitas. São
          considerados indicadores conforme dados de projeção futura do segmento
          do projeto em questão.
        </>
      ),
    },
  ]

  return (
    <Layout location={location} mainFolder={location.pathname}>
      <SEO title="Controladoria - Estudos de Viabilidade Financeira - Valore Brasil" />
      <Slider slides={slides} />

      <section className={styles.solutions}>
        <div className="pt-3 pb-5">
          <div className="container">
            <SectionTitle
              title="Nosso Propósito"
              className="solutionsTitle"
              description="Propor soluções através de estudos de viabilidade econômico financeiros
              para embasar as tomadas de decisões."
            />
          </div>
          <FeaturesSection items={featuresItems} />
        </div>

        <div className="container">
          <Divider />
        </div>

        <div className="py-5">
          <MethodologySection
            locationPath={location.pathname}
            items={methodologyItems}
            descripitionSection={`Conheça agora os 04 principais passos de nossa metodologia para
            realizar estudos de viabilidade econômico financeiros.`}
          />
        </div>
      </section>

      <SuccessCases listCases={cases} isNumericCases={true} />
      <AboutUs />
      <Statistics />
    </Layout>
  )
}

export default EstudosViabilidade
